import './UserFeedback.css'
import { useState } from "react"
import { Button, Modal } from "antd"


function UserFeedback() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Button onClick={() => setIsModalOpen(true)}>用户反馈</Button>
            <Modal 
                title="微信扫码进群" 
                open={isModalOpen} 
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                className="user-feedback-modal"
            >
                <img 
                    src="/resources/user_feedback_wechat_qrcode.JPG" 
                    alt="反馈二维码"
                    style={{ width: '100%', maxWidth: '300px' }}
                />
            </Modal>
        </>
    )
}

export default UserFeedback