import './Header.css';
import React from 'react';
import { Layout, Space } from 'antd';
import logo from '../../icons/logo.png';
import WeChatLogin from '../WorkSpace/components/Login/WeChatLogin';
import UserFeedback from '../WorkSpace/components/UserFeedback/UserFeedback';

const { Header } = Layout;

const HeaderComponent: React.FC = () => {

    return (
        <Header className='header'>
            <img className='logo' src={logo} alt="logo" />
            <div className='title'>叮当AI助手</div>
            <div className='menu-item'>图片批量水印</div>
            <Space className='userArea'>
                <UserFeedback />
                <WeChatLogin />
            </Space>
        </Header>
    );
};

export default HeaderComponent;