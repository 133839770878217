import './Sidebar.css';
import React, { useContext } from 'react';
import { Slider, Col, Row } from 'antd';
import { FileCenterContext, IFileCenterContext } from '@/FileCenterProvider';

function CustomLeftTop() {
    const {
        offsetLeft,
        setOffsetLeft,
        offsetTop,
        setOffsetTop,
    }
        = useContext<IFileCenterContext>(FileCenterContext);

    const offsetLeftInteger = Math.round(offsetLeft)
    const offsetTopInteger = Math.round(offsetTop)

    return (
        <>
            <Row>
                <Col span={4} className='text'>
                    左边
                </Col>
                <Col span={16}>
                    <Slider
                        min={0}
                        max={100}
                        step={1}
                        onChange={setOffsetLeft}
                        value={offsetLeftInteger}
                    />
                </Col>
                <Col span={4} className='text flex-end'>{offsetLeftInteger}%</Col>
            </Row>
            <Row>
                <Col span={4} className='text'>
                    顶部
                </Col>
                <Col span={16}>
                    <Slider
                        min={0}
                        max={100}
                        step={1}
                        onChange={setOffsetTop}
                        value={offsetTopInteger}
                    />
                </Col>
                <Col span={4} className='text flex-end'>{offsetTopInteger}%</Col>
            </Row>
        </>
    )
}

export default React.memo(CustomLeftTop);