import EXIF from 'exif-js';
import { fileCenter, FileInfo } from "./FileCenter";

interface FontProps {
    fontFamily: string, fontSize: number
}

interface IPoint {
    x: number;
    y: number;
}

interface Rect {
    left: number;
    top: number;
    width: number;
    height: number;
}

interface WaterMarkConfig {
    waterMarkTemplate: string, fontName: string, fontSize: number, fontColor: string, angel: number, tran: number, offsetLeft: number, offsetTop: number, posType: number
}

function initMeasureText() {
    const div = document.createElement('div')
    div.id = 'measure-text'
    div.innerHTML = ""
    div.style.cssText = "visibility: hidden;display: 'inline-block';position:absolute;white-space: nowrap;line-height: 1;"
    document.body.appendChild(div)
}

initMeasureText()


function getImageRatio(originWidth: number, originHeight: number, containerWidth: number, containerHeight: number) {
    if (originWidth <= containerWidth && originHeight <= containerHeight) {
        return 1;
    }

    const widthRatio = containerWidth / originWidth;
    const heightRatio = containerHeight / originHeight;
    return Math.min(widthRatio, heightRatio)
}

function getTextWH(text: string, font: FontProps) {
    const { fontFamily, fontSize } = font
    const div = document.getElementById('measure-text')
    if (!div) {
        return { width: 0, height: 0 }
    }
    div.innerHTML = text
    div.style.fontFamily = fontFamily
    div.style.fontSize = fontSize + 'px'
    const rect = div.getBoundingClientRect()
    return { width: rect.width, height: rect.height }
}

function createCanvas() {
    const canvas = document.createElement("canvas");
    return canvas
}

function resizeCanvas(canvas: HTMLCanvasElement, width: number, height: number) {
    canvas.width = width
    canvas.height = height
}

function canvas2Img(canvas: HTMLCanvasElement, imgType: string, quality: number = 1) {
    return new Promise<string>((resolve, reject) => {
        canvas.toBlob(function (blob) {
            if (!blob) {
                resolve('')
                return
            }
            var url = URL.createObjectURL(blob);
            resolve(url)
        }, imgType, quality);
    })
}

function canvas2Blob(canvas: HTMLCanvasElement, imgType: string, quality: number = 1) {
    return new Promise<Blob | null>((resolve, reject) => {
        canvas.toBlob(function (blob) {
            if (!blob) {
                resolve(null)
                return
            }
            resolve(blob)
        }, imgType, quality);
    })
}

function download(url: string, fileName: string) {
    // 创建一个临时链接并模拟点击下载
    var a = document.createElement('a');
    a.href = url
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

function throttle(func: any, delay: number) {
    let lastTime = 0;

    return (args: any) => {
        const now = Date.now();

        if (now - lastTime >= delay) {
            lastTime = now;
            func(args);
        }
    };
}

async function collectCanvas(waterMarkConfig: WaterMarkConfig, cb: (canvas: HTMLCanvasElement, file: FileInfo) => void): Promise<void> {
    const canvas = createCanvas();
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return;
    }

    const { waterMarkTemplate, fontName, fontSize, fontColor, angel, tran, offsetLeft, offsetTop, posType } = waterMarkConfig

    const fileList = fileCenter.getFileInfoList();

    for (let fileInfo of fileList) {
        const { file, image: img } = fileInfo;
        const { width, height } = img;
        canvas.width = width;
        canvas.height = height;
        // 当你设置 <canvas> 元素的 width 和 height 属性时，会重置画布的内容,并且重置绘图上下文（ctx）

        ctx.fillStyle = fontColor;
        ctx.font = `${fontSize}px ${fontName},sans-serif`;
        ctx.textBaseline = 'top';
        ctx.textAlign = 'start';

        ctx.drawImage(img, 0, 0, width, height);
        const imageTakenTime = await getImageTakenTime(file)
        const waterMark = getString(waterMarkTemplate, imageTakenTime)
        const { width: waterMarkWidth, height: waterMarkHeight } = getTextWH(waterMark, { fontFamily: fontName, fontSize });

        if (posType !== 0) {
            const waterMarkLeft = offsetLeft / 100 * width - waterMarkWidth / 2
            const waterMarkTop = offsetTop / 100 * height - waterMarkHeight / 2

            ctx.translate(waterMarkLeft, waterMarkTop);
            ctx.rotate(angel * Math.PI / 180);
            ctx.globalAlpha = 1 - tran / 100;
            ctx.fillText(waterMark, 0, 0);
        } else {
            const start = fontSize
            const distanceX = fontSize * 2 + waterMarkWidth
            const distanceY = fontSize * 2 + waterMarkHeight
            let i = 0;
            while (start + i * distanceX < width) {
                let j = 0;
                while (start + j * distanceY < height) {
                    const curWaterMarkLeft = start + i * distanceX
                    const curWaterMarkTop = start + j * distanceY
                    // 以水印中心为原点 旋转
                    ctx.translate(curWaterMarkLeft + waterMarkWidth / 2, curWaterMarkTop + waterMarkHeight / 2);
                    ctx.rotate(angel * Math.PI / 180);
                    ctx.globalAlpha = 1 - tran / 100;
                    ctx.fillText(waterMark, - waterMarkWidth / 2, -waterMarkHeight / 2);
                    ctx.setTransform(1, 0, 0, 1, 0, 0);
                    j++
                }
                i++
            }
        }
        cb(canvas, fileInfo)
    }
}

function isSupportFileSystemHandle() {
    return (window as any).showOpenFilePicker && (window as any).DataTransferItem.prototype.getAsFileSystemHandle;
}

// 添加这个新函数来获取图片的拍摄时间
async function getImageTakenTime(file: File): Promise<Date> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = function (e) {
            if (!e.target?.result) {
                resolve(new Date(file.lastModified));
                return;
            }

            try {
                const exif = EXIF.readFromBinaryFile(e.target.result);
                if (exif?.DateTimeOriginal) {
                    // EXIF 日期格式通常为 "YYYY:MM:DD HH:mm:ss"
                    const [date, time] = exif.DateTimeOriginal.split(' ');
                    const [year, month, day] = date.split(':');
                    const [hour, minute, second] = time.split(':');
                    resolve(new Date(year, month - 1, day, hour, minute, second));
                } else {
                    // 如果没有拍摄时间，返回文件的最后修改时间
                    resolve(new Date(file.lastModified));
                }
            } catch (error) {
                console.error('Error reading EXIF data:', error);
                // 发生错误时返回文件的最后修改时间
                resolve(new Date(file.lastModified));
            }
        };
        reader.readAsArrayBuffer(file);
    });
}


function getString(waterMarkTemplate: string, date: Date): string {
    const formatMap: { [key: string]: string } = {
        'YYYY': date.getFullYear().toString(),
        'MM': (date.getMonth() + 1).toString().padStart(2, '0'),
        'DD': date.getDate().toString().padStart(2, '0'),
        'HH': date.getHours().toString().padStart(2, '0'),
        'mm': date.getMinutes().toString().padStart(2, '0'),
        'ss': date.getSeconds().toString().padStart(2, '0')
    };

    return waterMarkTemplate.replace(/\{([^}]+)\}/g, (match, p1) => {
        let result = p1;
        for (const [placeholder, value] of Object.entries(formatMap)) {
            result = result.replace(placeholder, value);
        }
        return result;
    });
}

// 封装请求方法
async function request(url: string, options: RequestInit = {}) {
    const token = localStorage.getItem('token');

    const headers = {
        'Content-Type': 'application/json',
        // 在请求头中携带 token
        'Authorization': `Bearer ${token}`,
        ...options.headers,
    };

    const response = await fetch(url, {
        ...options,
        headers,
    });

    return response.json();
}

function formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 B';
    const k = 1024;
    const sizes = ['B', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}


export { formatFileSize, request, getImageRatio, getTextWH, createCanvas, resizeCanvas, canvas2Img, canvas2Blob, download, throttle, collectCanvas, isSupportFileSystemHandle, getString, getImageTakenTime };
export type { IPoint, Rect, WaterMarkConfig };
