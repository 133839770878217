import { fileCenter } from '@/FileCenter';
import React, { useContext, useRef } from 'react';
import { FileCenterContext } from '@/FileCenterProvider';
import { isSupportFileSystemHandle } from '@/utils';

interface UploadProps {
    onClickUpload: () => void,
    onDropUpload: (e: any) => void,
    onClear: () => void,
}

interface IProps {
    className?: string,
    enableDrag?: boolean,
    children: (props: UploadProps) => React.ReactNode
}

function UploadPage(props: IProps) {

    const { setFileIdList } = useContext(FileCenterContext);
    const refFileInput = useRef<HTMLInputElement>(null)

    function handleClick() {
        if (isSupportFileSystemHandle()) {
            handleOpenFilePicker()
            return;
        }
        refFileInput.current?.click()
    }

    async function handleOpenFilePicker() {
        if (!isSupportFileSystemHandle()) {
            return;
        }

        try {
            // 1. 打开文件选择对话框，让用户选择文件
            const fileHandles = await (window as any).showOpenFilePicker({
                types: [
                    {
                        description: '图片选择',
                        accept: { 'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp'] }
                    }
                ],
                multiple: true,
            });
            if (fileHandles.length === 0) {
                return;
            }
            fileCenter.handleUploadFileHandles(fileHandles, setFileIdList)

        } catch (error) {
            console.error('未选择文件:', error);
        }
    }

    function handlePrevent(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    async function handleDrop(e: any) {
        if (!props.enableDrag) {
            return;
        }
        // 阻止导航
        e.preventDefault();
        if (!isSupportFileSystemHandle()) {
            handleFiles(e)
            return;
        }
        handleFileHandles(e)
    }

    function handleFiles(e: any) {
        const dt = e.dataTransfer;
        const files = dt.files;
        fileCenter.handleUpload(files, setFileIdList)
        handlePrevent(e)
    }

    async function handleFileHandles(e: any) {
        const promises = [];
        // 按文件顺序收集文件句柄
        const fileHandleMap = new Map();
        // 处理所有条目
        for (const item of e.dataTransfer.items) {
            // 对于文件/目录条目，kind 将是“file”
            if (item.kind === "file") {
                fileHandleMap.set(item, null)
                const promise = item.getAsFileSystemHandle().then((handler: any) => {
                    if (handler.kind === "file") {
                        // 处理文件句柄
                        fileHandleMap.set(item, handler)
                    }
                });
                promises.push(promise); // 将 Promise 推入数组
            }
        }

        // 等待所有 Promise 完成
        await Promise.all(promises);
        // 如果收集到文件句柄，使用 handleUploadFileHandles 处理
        if (fileHandleMap.size > 0) {
            const fileHandles = Array.from(fileHandleMap.values());
            fileCenter.handleUploadFileHandles(fileHandles, setFileIdList);
            return;
        }
    }

    function handleChange(event: any) {
        const files = event.target.files;
        fileCenter.handleUpload(files, setFileIdList)
    }

    function handleClear() {
        fileCenter.clear()
        setFileIdList([])
    }

    const uploadProps = {
        onClickUpload: handleClick,
        onDropUpload: handleDrop,
        onClear: handleClear,
    }

    return (
        <div className={props.className} onDragEnter={handlePrevent} onDragOver={handlePrevent} onDragLeave={handlePrevent} onDrop={handleDrop}>
            <input ref={refFileInput} style={{ display: 'none' }} type="file" accept="image/*" multiple onChange={handleChange} />
            {props.children(uploadProps)}
        </div>
    )
}

export default React.memo(UploadPage)