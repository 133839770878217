import './AnchorDot.css'
import React from 'react';

export enum AnchorDotType {
    LeftTop = 0,
    RightTop = 1,
    LeftBottom = 2,
    RightBottom = 3,
}

interface IProps {
    anchorDotType: AnchorDotType;
    size: number;
    onSelect: (anchorDotType: AnchorDotType) => void;
}

export function getAnchorDotInfo(anchorDotType: AnchorDotType, size: number) {
    let left, right, top, bottom, cursorStyle
    switch (anchorDotType) {
        case AnchorDotType.LeftTop:
            left = `${-size / 2}px`
            top = `${-size / 2}px`
            cursorStyle = 'nwse-resize'
            break
        case AnchorDotType.RightTop:
            right = `${-size / 2}px`
            top = `${-size / 2}px`
            cursorStyle = 'nesw-resize'
            break
        case AnchorDotType.LeftBottom:
            left = `${-size / 2}px`
            bottom = `${-size / 2}px`
            cursorStyle = 'nesw-resize'
            break
        case AnchorDotType.RightBottom:
        default:
            right = `${-size / 2}px`
            bottom = `${-size / 2}px`
            cursorStyle = 'nwse-resize'
            break
    }
    return { left, top, right, bottom, width: size, height: size, cursor: cursorStyle }
}

function AnchorDot(props: IProps) {
    const { anchorDotType, size, onSelect } = props

    const handleMouseDown = (e: React.MouseEvent) => {
        onSelect(anchorDotType)
        e.stopPropagation()
    }

    const { left, top, right, bottom, cursor } = getAnchorDotInfo(anchorDotType, size)

    return (
        <div className='anchor-dot' style={{ width: `${size}px`, height: `${size}px`, left, top, right, bottom, cursor }} onMouseDown={handleMouseDown} />
    )
}

export default React.memo(AnchorDot)