import EXIF from "exif-js";

interface FileReadResult {
    dataUrl: string,
    blob: ArrayBuffer | null
}

function generateUniqueId(): string {
    return Date.now() + '-' + Math.floor(Math.random() * 10000);
}

class FileInfo {
    private _fileId: string;
    private _file: File;
    private _image: HTMLImageElement;
    private _fileDataUrl: string;
    private _fileHandle: FileSystemFileHandle | null;
    private _imageTakenTime: Date;

    constructor(file: File, image: HTMLImageElement, fileDataUrl: string, fileHandle?: FileSystemFileHandle) {
        this._fileId = generateUniqueId()
        this._file = file
        this._image = image
        this._fileDataUrl = fileDataUrl
        this._fileHandle = fileHandle || null
        this._imageTakenTime = new Date()
    }

    get fileId() {
        return this._fileId
    }

    get file() {
        return this._file
    }

    get image() {
        return this._image
    }

    get fileDataUrl() {
        return this._fileDataUrl
    }

    get fileHandle() {
        return this._fileHandle
    }

    get imageTakenTime() {
        return this._imageTakenTime
    }

    set imageTakenTime(date: Date) {
        this._imageTakenTime = date
    }
}

class FileCenter {
    private fileMap: Map<string, FileInfo>
    constructor() {
        this.fileMap = new Map<string, FileInfo>()
    }

    getFileInfo(fileId: string): FileInfo | undefined {
        return this.fileMap.get(fileId)
    }

    setFileInfo(fileId: string, fileInfo: FileInfo) {
        return this.fileMap.set(fileId, fileInfo)
    }

    removeFileInfo(fileId: string) {
        return this.fileMap.delete(fileId)
    }

    getFileInfoList() {
        const result: FileInfo[] = []
        this.fileMap.forEach((value, key) => {
            result.push(value)
        })
        return result
    }

    getCount(): number {
        return this.fileMap.size
    }

    clear() {
        this.fileMap.clear()
    }

    getTest() {
        return "FileCenter"
    }

    getImageTakenTime(blob: ArrayBuffer | null, file: File): Date {
        const exif = EXIF.readFromBinaryFile(blob);
        if (exif?.DateTimeOriginal) {
            // EXIF 日期格式通常为 "YYYY:MM:DD HH:mm:ss"
            const [date, time] = exif.DateTimeOriginal.split(' ');
            const [year, month, day] = date.split(':');
            const [hour, minute, second] = time.split(':');
            return new Date(year, month - 1, day, hour, minute, second)

        } else {
            // 如果没有拍摄时间，返回文件的最后修改时间
            return new Date(file.lastModified);
        }
    }

    private readFile(file: File): Promise<FileReadResult> {
        return new Promise((resolve, reject) => {
            let exifLoaded = false
            let imageLoaded = false

            const res: FileReadResult = { dataUrl: '', blob: null }
            const exifReader = new FileReader();
            exifReader.onload = function (e) {
                exifLoaded = true
                if (!e.target?.result) return;
                res.blob = e.target.result as ArrayBuffer
                if (imageLoaded) {
                    resolve(res)
                }
            }
            exifReader.readAsArrayBuffer(file);

            const imageReader = new FileReader();
            imageReader.onload = function (e) {
                imageLoaded = true
                if (!e.target) return;
                if (typeof e.target.result !== 'string') {
                    return;
                }
                const img = new Image();
                img.src = e.target.result;
                res.dataUrl = e.target.result;
                if (exifLoaded) {
                    resolve(res)
                }
            }
            imageReader.readAsDataURL(file);
        });
    }

    handleUpload(files: File[], cb: (list: string[]) => void) {
        const self = this
        let loadedCount = 0
        files.forEach(async file => {
            const { blob, dataUrl } = await self.readFile(file)
            const img = new Image();
            img.src = dataUrl;

            const fileInfo = new FileInfo(file, img, dataUrl)
            fileInfo.imageTakenTime = self.getImageTakenTime(blob, file)
            self.setFileInfo(fileInfo.fileId, fileInfo)
            loadedCount += 1
            if (loadedCount === files.length) {
                const list = self.getFileInfoList().map(fileInfo => fileInfo.fileId)
                cb && cb(list)
            }
        })
    }

    async handleUploadFileHandles(fileHandles: FileSystemFileHandle[], cb: (list: string[]) => void) {
        const self = this
        let loadedCount = 0
        fileHandles.forEach(async fileHandle => {
            const file = await fileHandle.getFile();
            const { blob, dataUrl } = await self.readFile(file)
            const img = new Image();
            img.src = dataUrl;

            const fileInfo = new FileInfo(file, img, dataUrl, fileHandle)
            fileInfo.imageTakenTime = self.getImageTakenTime(blob, file)
            self.setFileInfo(fileInfo.fileId, fileInfo)
            loadedCount += 1
            if (loadedCount === fileHandles.length) {
                const list = self.getFileInfoList().map(fileInfo => fileInfo.fileId)
                cb && cb(list)
            }
        })
    }
}

const fileCenter = new FileCenter();
export { FileInfo, fileCenter }

