


class CustomEvents {

    static OPEN_LOGIN_PANEL = 'OPEN_LOGIN_PANEL';

    static dispatch(eventName: string, data?: any) {
        const event = new Event(eventName, data);
        window.dispatchEvent(event);
    }
}

export default CustomEvents;