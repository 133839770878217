import './App.css';
import WorkSpace from '@components/WorkSpace/WorkSpace';
import Sidebar from '@components/Sidebar/Sidebar';
import FileCenterProvider from '@/FileCenterProvider';
import Header from './components/Header/Header';
// import FileSystemDemo from './FileSystemDemo';

function App() {

  return (
    <div className="app">
      <FileCenterProvider>
        <Header />
        <div className='content'>
          <WorkSpace />
          <Sidebar />
        </div>
      </FileCenterProvider>
      {/* <FileSystemDemo /> */}
    </div>
  );
}

export default App;
