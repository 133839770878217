import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from '@/App';
import reportWebVitals from './reportWebVitals';
import posthog from 'posthog-js'

posthog.init('phc_LvGN4Evc7eqHaHXu5PPFBX4oY0pR3nme86FOmq8FypR',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)

const reactContainer = document.getElementById('root')
if (!reactContainer) {
  throw new Error('no react container')
}
const root = ReactDOM.createRoot(reactContainer);
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
