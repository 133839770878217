
interface IProps {
    size: number;
    // 锚点中心相对于水印内容位置
    left: number;
    // 线段长度
    lineLen: number;
    // 线段宽度
    lineWidth: number;
    onSelect: (event: React.MouseEvent<HTMLDivElement>) => void;
}

function RotateAnchorDot(props: IProps) {
    const { size, left, lineLen, lineWidth, onSelect } = props

    function handleMouseDown(e: React.MouseEvent<HTMLDivElement>) {
        onSelect(e)
    }

    return (
        <>
            <div style={{ position: 'absolute', width: `${lineWidth}px`, height: `${lineLen}px`, left: `${left - lineWidth / 2}px`, top: `${-lineLen}px`, backgroundColor: 'black' }}></div>
            <div onMouseDown={handleMouseDown} style={{ position: 'absolute', width: `${size}px`, height: `${size}px`, left: `${left - size / 2}px`, top: `${-lineLen}px`, backgroundColor: 'black' }}></div>
        </>

    )
}

export default RotateAnchorDot;