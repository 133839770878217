// src/GridSelector.js
import React, { useState } from 'react';
import './GridSelector.css';

interface IProps {
  value: number;
  onChange?: (index: number) => void;
}

const GridSelector = (props: IProps) => {
  const { value, onChange } = props;
  const arr = Array(9).fill(false);
  arr[value] = true;
  const [selected, setSelected] = useState(arr);

  const handleClick = (index: number) => {
    const newSelected = Array(9).fill(false);
    newSelected[index] = true;
    setSelected(newSelected);
    onChange && onChange(index);
  };

  const getClassNames = (index: number) => {
    const row = Math.floor(index / 3);
    const col = index % 3;
    return `grid-item row-${row} col-${col} ${selected[index] ? 'selected' : ''}`;
  };

  return (
    <div className="grid-container">
      {selected.map((isSelected, index) => (
        <div
          key={index}
          className={getClassNames(index)}
          onClick={() => handleClick(index)}
        >
          <div className="circle"></div>
          <div className="line vertical"></div>
          <div className="line horizontal"></div>
        </div>
      ))}
    </div>
  );
};

export default GridSelector;
