
import './WorkSpace.css'
import React, { useContext, useState } from 'react';
import UploadPage from './UploadPage';
import ThumbnailList from './ThumbnailList/ThumbnailList'
import ImageEditView from './ImageEditView'
import { FileCenterContext } from '@/FileCenterProvider';
import { fileCenter, FileInfo } from '@/FileCenter';
import Demo from './Demo';

function WorkSpace() {

    const [selectedFileInfo, setSelectedFileInfo] = useState<FileInfo | undefined>(undefined)
    const { setFileIdList, fileIdList } = useContext(FileCenterContext);

    function handleSelect(fileInfo: FileInfo) {
        setSelectedFileInfo(fileInfo)
    }

    function handleRemove(fileInfo: FileInfo) {
        fileCenter.removeFileInfo(fileInfo.fileId)
        const newFileIdList = fileIdList.filter(fileId => fileId !== fileInfo.fileId)
        setFileIdList(newFileIdList)
    }

    if (fileIdList.length === 0) {
        return (
            <div className="workspace-container">
                < UploadPage />
            </div>
        )
    }

    const fileInfo = fileCenter.getFileInfo(fileIdList[0])
    // 刚开始用户没有点击右侧的缩略图列表，则默认选中第0个
    const selectedItem = selectedFileInfo || fileInfo
    return (
        <div className="workspace-container">
            <ThumbnailList selectedItem={selectedItem} onSelect={handleSelect} onRemove={handleRemove} />
            {/* <ImageEditView selectedItem={selectedItem} /> */}
            <FileCenterContext.Consumer>
                {(value) => <ImageEditView selectedItem={selectedItem} {...value} />}
                {/* {(value) => <Demo selectedItem={selectedItem} {...value} />} */}
            </FileCenterContext.Consumer>
        </div>
    )

}

export default React.memo(WorkSpace)