import './WeChatLogin.css'
import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, message, Popover } from 'antd';
import { nanoid } from 'nanoid';
import { FileCenterContext } from '@/FileCenterProvider';
import CustomEvents from '@/constants/customEvents';

let qrKey = '';

function WeChatLogin() {
    const { isLoggedIn, setIsLoggedIn, userInfo, setUserInfo } = useContext(FileCenterContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [polling, setPolling] = useState<NodeJS.Timeout | null>(null);

    const showModal = () => {
        setIsModalOpen(true);
        getQrCode();
    };

    useEffect(() => {
        const handleOpenLoginPanel = () => {
            showModal()
        };

        window.addEventListener(CustomEvents.OPEN_LOGIN_PANEL, handleOpenLoginPanel);

        return () => {
            window.removeEventListener(CustomEvents.OPEN_LOGIN_PANEL, handleOpenLoginPanel);
        };
    }, []);

    // 获取微信登录二维码
    const getQrCode = async () => {
        qrKey = nanoid();
        setTimeout(() => {
            new (window as any).WxLogin({
                self_redirect: true,
                id: "login_container",
                appid: "wxb59cb001f08739b1",
                scope: "snsapi_login",
                redirect_uri: encodeURIComponent(`https://www.x2077.top/api/wx/callback?qrKey=${qrKey}`),
                state: "",
                style: "",
                href: ""
            });
            startPolling(qrKey);
        }, 100)
    };

    // 轮询检查扫码状态
    const startPolling = (qrKey: string) => {
        const timer = setInterval(async () => {
            try {
                // 这里需要替换为你的后端API
                const response = await fetch(`/api/wx/checkLogin?qrKey=${qrKey}`);
                const data = await response.json();

                if (data.success === true) {
                    message.success('扫码成功！');
                    clearInterval(timer);
                    setIsModalOpen(false);
                    // 处理登录成功后的逻辑
                    // 处理登录成功后的逻辑
                    if (data.token) {
                        // 存储 token
                        localStorage.setItem('token', data.token);
                    }

                    if (data.userInfo) {
                        // 存储用户信息
                        localStorage.setItem('userInfo', JSON.stringify(data.userInfo));
                    }
                    setIsLoggedIn(true);
                    setUserInfo(data.userInfo);
                }
            } catch (error) {
                clearInterval(timer);
                message.error('检查扫码状态失败');
            }
        }, 2000);
        setPolling(timer);
    };

    useEffect(() => {
        return () => {
            if (polling) {
                clearInterval(polling);
            }
        };
    }, [polling]);

    const handleCancel = () => {
        setIsModalOpen(false);
        if (polling) {
            clearInterval(polling);
        }
    };

    const logout = () => {
        // 清除登录信息
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        setIsLoggedIn(false);
        setUserInfo(null);
        message.success('已退出登录');
    };

    // 用户信息面板内容
    const userPanel = (
        <div className="user-panel">
            <Button
                type="text"
                danger
                onClick={logout}
                className="logout-btn"
            >
                退出登录
            </Button>
        </div>
    );

    return (
        <div className="wechat-login">
            {!isLoggedIn && <Button type="primary" onClick={showModal}>登录/注册</Button>}
            {isLoggedIn && (
                <Popover
                    content={userPanel}
                    trigger="hover"
                    placement="bottom"
                    arrow={true}
                >
                    <div className="user-trigger">
                        <img
                            src={userInfo?.headimgurl}
                            alt="avatar"
                            className="user-avatar-small"
                        />
                    </div>
                </Popover>
            )}

            <Modal
                title="微信扫码登录"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                centered
            >
                <div id="login_container" className="qrcode-container"></div>
            </Modal>
        </div>
    );
}

export default React.memo(WeChatLogin);