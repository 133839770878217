import './ImageEditView.css';
import React, { createRef } from 'react';
import { FileInfo } from '@/FileCenter';
import { IFileCenterContext } from '@/FileCenterProvider';
import { getImageRatio, getString, getTextWH, throttle } from '@/utils';
import type { Rect } from '@/utils';
import AnchorDot, { AnchorDotType, getAnchorDotInfo } from './components/AnchorDot/AnchorDot';
import RotateAnchorDot from './components/AnchorDot/RotateAnchorDot';


interface IProps extends IFileCenterContext {
    selectedItem: FileInfo | undefined,
}

interface IState {
    left: number;
    top: number;
    width: number;
    height: number;
    cursorStyle: string | undefined;
    hasOutline: boolean;
}



class ImageEditView extends React.PureComponent<IProps, IState> {
    private readonly AnchorDotSize = 8;

    private ratio = 1;
    private startMoveFlag = false;
    private startSetSizeProps: any = null;
    private startRotateProps: any = null;

    private xOffsetWaterMark = 0;
    private yOffsetWaterMark = 0;
    private waterMarkContainerRect: Rect | undefined = undefined
    private refWaterMarkContainer = createRef<HTMLDivElement>();
    private refWaterMark = createRef<HTMLDivElement>()
    private refContainer = createRef<HTMLDivElement>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            left: 0,
            top: 0,
            width: 0,
            height: 0,
            cursorStyle: undefined,
            hasOutline: false,
        };

    }

    componentDidMount(): void {
        window.addEventListener('resize', this.updateSize)
        window.addEventListener('mousemove', this.handleMouseMove)
        window.addEventListener('mouseup', this.handleMouseUp)

        this.updateSize()
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.updateSize)
        window.removeEventListener('mousemove', this.handleMouseMove)
        window.removeEventListener('mouseup', this.handleMouseUp)
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.selectedItem !== this.props.selectedItem) {
            this.updateSize()
        }
    }

    private getWaterMark() {
        const { waterMarkTemplate,
            selectedItem,
        } = this.props
        if (!selectedItem) return ''
        const imageTakenTime = selectedItem.imageTakenTime
        return getString(waterMarkTemplate, imageTakenTime)
    }

    private getWaterMarkWH() {
        const {
            fontName,
            fontSize,
        } = this.props
        const curFontSize = fontSize * this.ratio
        const waterMark = this.getWaterMark()
        return getTextWH(waterMark, { fontFamily: fontName, fontSize: curFontSize })
    }

    private updateSize = () => {
        const container = this.refContainer.current;
        const selectedItem = this.props.selectedItem;
        if (!container || !selectedItem) return;
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        const image = selectedItem.image;
        const originWidth = image.width;
        const originHeight = image.height;
        this.ratio = getImageRatio(originWidth, originHeight, containerWidth, containerHeight);
        const adaptiveWidth = this.ratio * originWidth;
        const adaptiveHeight = this.ratio * originHeight;
        const left = (containerWidth - adaptiveWidth) / 2;
        const top = (containerHeight - adaptiveHeight) / 2;
        this.setState({ left, top, width: adaptiveWidth, height: adaptiveHeight })
    }

    private handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        // 在水印文字上mousdown,不能让outline消失
        event.stopPropagation()
        this.startMoveFlag = true

        if (!this.refWaterMarkContainer.current) {
            return;
        }
        this.waterMarkContainerRect = this.refWaterMarkContainer.current.getBoundingClientRect()

        if (!this.refWaterMark.current) {
            return;
        }
        const waterMarkRect = this.refWaterMark.current.getBoundingClientRect()
        this.xOffsetWaterMark = event.clientX - waterMarkRect.left
        this.yOffsetWaterMark = event.clientY - waterMarkRect.top
        this.setState({ hasOutline: true })
    }

    private handleWaterMarkContainerMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        // 在水印文字外面mousedown，要让outline消失
        this.setState({ hasOutline: false })
    }

    private handleRotateAnchorDotMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        if (!this.refWaterMark.current) return;
        const waterMarkRect = this.refWaterMark.current.getBoundingClientRect()
        const { left, top, width, height, bottom, right } = waterMarkRect

        this.startRotateProps = {
            waterMarkRect: { left, top, width, height, bottom, right },
        }
        event.stopPropagation()
    }

    /*
    如果不加throttle, CustomLeftTop的 antd Slider组件会一直更新，导致控制台出现如下警告：
    Warning: Maximum update depth exceeded. This can happen when a component calls setState inside useEffect, but useEffect either doesn't have a dependency array, or one of the dependencies changes on every render. 
    */

    private handleMouseMove = throttle((event: MouseEvent) => {
        if (this.startMoveFlag) {
            this.moveWaterMark(event);
            return;
        }

        if (this.startSetSizeProps) {
            this.adjustWaterMarkSize(event);
            return;
        }

        if (this.startRotateProps) {
            this.rotateWaterMark(event);
            return;
        }
    }, 25)

    private moveWaterMark = (event: MouseEvent) => {
        if (!this.waterMarkContainerRect || !this.waterMarkContainerRect) return;
        const { width, height } = this.state
        const { width: waterMarkWidth, height: waterMarkHeight } = this.getWaterMarkWH()

        const x = event.clientX - this.waterMarkContainerRect.left - this.xOffsetWaterMark
        const y = event.clientY - this.waterMarkContainerRect.top - this.yOffsetWaterMark
        const ratioDx = (x + waterMarkWidth / 2) / width * 100
        const rationDy = (y + waterMarkHeight / 2) / height * 100
        const newOffsetLeft = Math.min(100, Math.max(0, ratioDx))
        const newOffsetTop = Math.min(100, Math.max(0, rationDy))
        this.props.setOffsetLeft(newOffsetLeft)
        this.props.setOffsetTop(newOffsetTop)
    }

    private adjustWaterMarkSize = (event: MouseEvent) => {
        if (!this.startSetSizeProps || !this.waterMarkContainerRect) return;
        const { fontName } = this.props
        const waterMark = this.getWaterMark()
        const { diagonalAnchorX, diagonalAnchorY, anchorDotType, waterMarkRect, fontSize } = this.startSetSizeProps;
        const originDistance = Math.sqrt(waterMarkRect.width * waterMarkRect.width + waterMarkRect.height * waterMarkRect.height)
        const clientX = event.clientX;
        const clientY = event.clientY;
        const distance = Math.sqrt((clientX - diagonalAnchorX) * (clientX - diagonalAnchorX) + (clientY - diagonalAnchorY) * (clientY - diagonalAnchorY));
        const originRatio = distance / originDistance;
        const newFontSize = fontSize * originRatio;
        const newFontSizeNum = Math.round(newFontSize)
        const newRatio = newFontSizeNum / fontSize
        const { width: newWaterMarkWidth, height: newWaterMarkHeight } = getTextWH(waterMark, { fontFamily: fontName, fontSize: newFontSizeNum * this.ratio })

        let newOffsetLeft = this.props.offsetLeft;
        let newOffsetTop = this.props.offsetTop;

        switch (anchorDotType) {

            case AnchorDotType.LeftTop:
                // 右下角位置不变
                // newOffsetLeft/100*this.state.width + newWaterMarkWidth/2 + this.waterMarkContainerRect.left === waterMarkRect.left+waterMarkRect.width
                // newOffsetTop/100*this.state.height + newWaterMarkHeight/2 + this.waterMarkContainerRect.top === waterMarkRect.top+waterMarkRect.height
                newOffsetLeft = (waterMarkRect.left + waterMarkRect.width - newWaterMarkWidth / 2 - this.waterMarkContainerRect.left) * 100 / this.state.width
                newOffsetTop = (waterMarkRect.top + waterMarkRect.height - newWaterMarkHeight / 2 - this.waterMarkContainerRect.top) * 100 / this.state.height
                break

            case AnchorDotType.RightTop:
                // 左下角位置不变
                newOffsetLeft = (waterMarkRect.left + newWaterMarkWidth / 2 - this.waterMarkContainerRect.left) * 100 / this.state.width;
                newOffsetTop = (waterMarkRect.top + waterMarkRect.height - newWaterMarkHeight / 2 - this.waterMarkContainerRect.top) * 100 / this.state.height
                break

            case AnchorDotType.LeftBottom:
                // 右上角位置不变
                newOffsetLeft = (waterMarkRect.left + waterMarkRect.width - newWaterMarkWidth / 2 - this.waterMarkContainerRect.left) * 100 / this.state.width
                newOffsetTop = (waterMarkRect.top + newWaterMarkHeight / 2 - this.waterMarkContainerRect.top) * 100 / this.state.height
                break

            case AnchorDotType.RightBottom:
                // 宽度变化了，但左上角位置不变
                // newOffsetLeft/100 * this.state.width  - (newWaterMarkWidth /2) + this.state.left = waterMarkRect.left
                newOffsetLeft = (waterMarkRect.left + newWaterMarkWidth / 2 - this.waterMarkContainerRect.left) * 100 / this.state.width;
                newOffsetTop = (waterMarkRect.top + newWaterMarkHeight / 2 - this.waterMarkContainerRect.top) * 100 / this.state.height
                break
        }

        this.props.setFontSize(newFontSizeNum);
        this.props.setOffsetLeft(newOffsetLeft)
        this.props.setOffsetTop(newOffsetTop)
    }

    private rotateWaterMark(event: MouseEvent) {
        if (!this.waterMarkContainerRect || !this.startRotateProps) return;
        const clientX = event.clientX;
        const clientY = event.clientY;
        const waterMarkRect = this.startRotateProps.waterMarkRect
        const waterMarkCenterX = waterMarkRect.left + waterMarkRect.width / 2
        const waterMarkCenterY = waterMarkRect.top + waterMarkRect.height / 2

        // tempAngel为向量与-y方向的夹角
        const tempAngel = Math.atan2(clientX - waterMarkCenterX, clientY - waterMarkCenterY) * 180 / Math.PI
        const angel = tempAngel < 0 ? -180 - tempAngel : 180 - tempAngel
        this.props.setAngel(Math.round(angel))
    }

    private handleMouseUp = () => {
        this.startMoveFlag = false;
        this.startSetSizeProps = null;
        this.startRotateProps = null;
        this.setState({ cursorStyle: undefined })
    }

    private getAnchorDiagonal(anchorDotType: AnchorDotType, waterMarkRect: any) {
        const { left, top, bottom, right } = waterMarkRect

        let diagonalAnchorX = 0
        let diagonalAnchorY = 0

        switch (anchorDotType) {
            case AnchorDotType.LeftTop:
                diagonalAnchorX = right
                diagonalAnchorY = bottom
                break

            case AnchorDotType.RightTop:
                diagonalAnchorX = left
                diagonalAnchorY = bottom
                break

            case AnchorDotType.LeftBottom:
                diagonalAnchorX = right
                diagonalAnchorY = top
                break

            case AnchorDotType.RightBottom:
            default:
                diagonalAnchorX = left
                diagonalAnchorY = top
        }
        return { diagonalAnchorX, diagonalAnchorY }
    }

    private handleAnchorDotSelect = (anchorDotType: AnchorDotType) => {
        if (!this.refWaterMark.current || !this.refWaterMarkContainer.current) return;

        const waterMarkRect = this.refWaterMark.current.getBoundingClientRect()
        const { left, top, width, height, bottom, right } = waterMarkRect

        this.waterMarkContainerRect = this.refWaterMarkContainer.current.getBoundingClientRect()

        this.startSetSizeProps = {
            fontSize: this.props.fontSize,
            anchorDotType,
            ...this.getAnchorDiagonal(anchorDotType, waterMarkRect),
            waterMarkRect: { left, top, width, height, bottom, right },
        }
        this.setState({ cursorStyle: getAnchorDotInfo(anchorDotType, this.AnchorDotSize).cursor })
    }

    public render() {
        const { selectedItem } = this.props
        if (!selectedItem) {
            return null
        }
        const { left, top, width, height } = this.state

        return (
            <div className='image-edit-view' style={{ cursor: this.state.cursorStyle }} ref={this.refContainer}>
                <img className="image-edit-img2" style={{ left: `${left}px`, top: `${top}px`, width: `${width}px`, height: `${height}px` }} src={selectedItem?.fileDataUrl} alt={selectedItem.file.name} />
                <div ref={this.refWaterMarkContainer} className='image-real-size' onMouseDown={this.handleWaterMarkContainerMouseDown} style={{ left: `${left}px`, top: `${top}px`, width: `${width}px`, height: `${height}px` }}>
                    {this.renderWaterMark()}
                </div>
            </div>
        )
    }

    private renderWaterMark() {
        const {
            fontName,
            fontSize,
            fontColor,
            angel,
            tran,
            posType,
            offsetLeft,
            offsetTop,
        } = this.props

        const waterMark = this.getWaterMark()
        const { width, height, cursorStyle, hasOutline } = this.state

        const curFontSize = fontSize * this.ratio
        const { width: waterMarkWidth, height: waterMarkHeight } = this.getWaterMarkWH()
        const waterMarkLeft = offsetLeft / 100 * width - waterMarkWidth / 2
        const waterMarkTop = offsetTop / 100 * height - waterMarkHeight / 2
        const opacity = 1 - tran / 100

        if (posType !== 0) {
            return (
                <div ref={this.refWaterMark} className='water-mark' style={
                    {
                        color: fontColor,
                        left: `${waterMarkLeft}px`,
                        top: `${waterMarkTop}px`,
                        fontSize: `${curFontSize}px`,
                        fontFamily: `"${fontName}" ,sans-serif`,
                        transform: `rotate(${angel}deg)`,
                        opacity,
                        cursor: cursorStyle ? undefined : 'move',
                        outline: hasOutline ? '1px solid black' : undefined
                    }
                }
                    onMouseDown={this.handleMouseDown}
                >
                    {hasOutline && <>
                        <AnchorDot anchorDotType={AnchorDotType.LeftTop} size={this.AnchorDotSize} onSelect={this.handleAnchorDotSelect} />
                        <AnchorDot anchorDotType={AnchorDotType.RightTop} size={this.AnchorDotSize} onSelect={this.handleAnchorDotSelect} />
                        <AnchorDot anchorDotType={AnchorDotType.LeftBottom} size={this.AnchorDotSize} onSelect={this.handleAnchorDotSelect} />
                        <AnchorDot anchorDotType={AnchorDotType.RightBottom} size={this.AnchorDotSize} onSelect={this.handleAnchorDotSelect} />
                        <RotateAnchorDot size={this.AnchorDotSize} left={waterMarkWidth / 2} lineLen={50} lineWidth={2} onSelect={this.handleRotateAnchorDotMouseDown} />
                    </>}
                    {waterMark}
                </div>
            )
        }

        const list = []
        const start = curFontSize
        const distanceX = curFontSize * 2 + waterMarkWidth
        const distanceY = curFontSize * 2 + waterMarkHeight
        let i = 0;
        while (start + i * distanceX < width) {
            let j = 0;
            while (start + j * distanceY < height) {
                const curWaterMarkLeft = start + i * distanceX
                const curWaterMarkTop = start + j * distanceY
                list.push(
                    <div key={`${i}-${j}`} className='water-mark' style={
                        {
                            color: fontColor,
                            left: `${curWaterMarkLeft}px`,
                            top: `${curWaterMarkTop}px`,
                            fontSize: `${curFontSize}px`,
                            fontFamily: `"${fontName}" ,sans-serif`,
                            transform: `rotate(${angel}deg)`,
                            opacity,
                        }
                    }>{waterMark}</div>
                );
                j++
            }
            i++
        }
        return list
    }
}

export default ImageEditView