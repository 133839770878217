
import './UploadPage.css'
import React from 'react';
import BatchUploadImg from '../images/batch_upload_img.svg';
import Upload from './components/Upload';

function UploadPage() {

    return (
        <Upload className='drop-container' enableDrag={true}>
            {(uploadProps) => (
                <div className='drop-container' onClick={uploadProps.onClickUpload}>
                    <img src={BatchUploadImg} className='upload-img' alt='upload' width="100px" height="100px" />
                    <div className='upload-text'>添加图片 或 拖拽到些区域</div>
                </div>
            )}
        </Upload>
    )
}

export default React.memo(UploadPage)