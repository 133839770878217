import React, { createContext, useEffect, useState } from 'react';

export interface IFileCenterContext {
    isLoggedIn: boolean;
    setIsLoggedIn: (isLoggedIn: boolean) => void;
    userInfo: any;
    setUserInfo: (userInfo: any) => void;

    fileIdList: string[];
    setFileIdList: (fileIdList: string[]) => void;
    //水印类型
    waterMarkType: string;
    setWaterMarkType: (waterMarkType: string) => void;
    //水印内容
    waterMarkTemplate: string,
    setWaterMarkTemplate: (waterMarkTemplate: string) => void;
    fontName: string,
    setFontName: (fontName: string) => void;
    // 在原始图片上的字体大小
    fontSize: number,
    setFontSize: (fontSize: number) => void;
    fontColor: string,
    setFontColor: (fontColor: string) => void;
    //方向
    angel: number,
    setAngel: (angel: number) => void;
    //透明度
    tran: number,
    setTran: (tran: number) => void;
    //位置类型，0 平铺 1 九宫格 2 自定义
    posType: number,
    setPosType: (posType: number) => void;
    // 水印中心相对于图片的偏移量
    offsetLeft: number,
    setOffsetLeft: (offsetLeft: number) => void;
    // 水印中心相对于图片的偏移量
    offsetTop: number,
    setOffsetTop: (offsetTop: number) => void;
    cellIndex: number,
    setCellIndex: (cellIndex: number) => void;
}

export const FileCenterContext = createContext<IFileCenterContext>({
    isLoggedIn: false,
    setIsLoggedIn: (isLoggedIn: boolean) => { },
    userInfo: null,
    setUserInfo: (userInfo: any) => { },

    fileIdList: [],
    setFileIdList: (fileIdList: string[]) => { },
    //水印类型
    waterMarkType: 'text',
    setWaterMarkType: (waterMarkType: string) => { },
    //水印内容模板
    waterMarkTemplate: '',
    setWaterMarkTemplate: (waterMarkTemplate: string) => { },
    fontName: 'Microsoft YaHei',
    setFontName: (fontName: string) => { },
    // 在原始图片上的字体大小
    fontSize: 48,
    setFontSize: (fontSize: number) => { },
    fontColor: '#1677ff',
    setFontColor: (fontColor: string) => { },
    //方向
    angel: 0,
    setAngel: (angel: number) => { },
    //透明度
    tran: 0,
    setTran: (tran: number) => { },
    //位置类型，0 平铺 1 九宫格 2 自定义
    posType: 0,
    setPosType: (posType: number) => { },
    // 水印内容相对于图片的偏移量
    offsetLeft: 0,
    setOffsetLeft: (offsetLeft: number) => { },
    // 水印内容相对于图片的偏移量
    offsetTop: 0,
    setOffsetTop: (offsetTop: number) => { },
    cellIndex: 0,
    setCellIndex: (cellIndex: number) => { },
})

interface IProps {
    children: React.ReactNode;
}

const FileCenterProvider = (props: IProps) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<any>(null);

    const [fileIdList, setFileIdList] = useState<any>([]);
    const [waterMarkType, setWaterMarkType] = useState<string>('text');
    const [waterMarkTemplate, setWaterMarkTemplate] = useState<string>('');
    const [fontName, setFontName] = useState<string>('Microsoft YaHei');
    const [fontSize, setFontSize] = useState<number>(48);
    const [fontColor, setFontColor] = useState<string>('#1677ff');
    const [angel, setAngel] = useState<number>(0);
    const [tran, setTran] = useState<number>(0);
    const [posType, setPosType] = useState<number>(2);
    const [offsetLeft, setOffsetLeft] = useState<number>(50);
    const [offsetTop, setOffsetTop] = useState<number>(50);
    const [cellIndex, setCellIndex] = useState<number>(4);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const userInfo = localStorage.getItem('userInfo');
        if (token && userInfo) {
            setIsLoggedIn(true);
            setUserInfo(JSON.parse(userInfo));
        }
    }, []);

    return (
        <FileCenterContext.Provider value={{
            isLoggedIn,
            setIsLoggedIn,
            userInfo,
            setUserInfo,

            fileIdList,
            setFileIdList,
            waterMarkType,
            setWaterMarkType,
            waterMarkTemplate,
            setWaterMarkTemplate,
            fontName,
            setFontName,
            fontSize,
            setFontSize,
            fontColor,
            setFontColor,
            angel,
            setAngel,
            tran,
            setTran,
            posType,
            setPosType,
            offsetLeft,
            setOffsetLeft,
            offsetTop,
            setOffsetTop,
            cellIndex,
            setCellIndex,
        }}>
            {props.children}
        </FileCenterContext.Provider>
    );
};

export default FileCenterProvider;
