
import './ThumbnailItem.css'
import classNames from 'classnames';
import { DeleteOutlined } from '@ant-design/icons';
import { FileInfo } from "@/FileCenter";
import { useState } from 'react';
import { Button, Popover } from 'antd';
import { formatFileSize } from '@/utils';

interface IProps {
    fileInfo: FileInfo | undefined;
    selectedItem: FileInfo | undefined;
    onSelect: (fileInfo: FileInfo) => void;
    onRemove: (fileInfo: FileInfo) => void;
}

function ThumbnailItem(props: IProps) {
    const [isHover, setIsHover] = useState(false)
    const { fileInfo, selectedItem, onSelect, onRemove } = props;
    if (!fileInfo) {
        return null
    }

    function handleMouseEnter() {
        console.log('enter')
        setIsHover(true)
    }

    function handleMouseLeave() {
        console.log('leave')
        setIsHover(false)
    }

    const cs = classNames('thumbnail-item', {
        'thumbnail-item-selected': fileInfo.fileId === selectedItem?.fileId
    })

    const hoverDivCS = classNames('hover-div', {
        'hover-div-show': isHover
    })

    const image = fileInfo.image

    const content = (
        <div className='thumbnail-item-popover-content'>
            <div className='info-row'>
                <span className='info-label'>名称：</span>
                <span className='info-value'>{fileInfo.file.name}</span>
            </div>
            <div className='info-row'>
                <span className='info-label'>大小：</span>
                <span className='info-value'>{formatFileSize(fileInfo.file.size)}</span>
            </div>
            <div className='info-row'>
                <span className='info-label'>类型：</span>
                <span className='info-value'>{fileInfo.file.type}</span>
            </div>
            <div className='info-row'>
                <span className='info-label'>尺寸：</span>
                <span className='info-value'>{image.width}x{image.height}</span>
            </div>
        </div>
    );

    return (
        <Popover placement="right" content={content}>
            <div className={cs} key={fileInfo.fileId} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => { onSelect(fileInfo) }}>
                {isHover && (
                    <div className={hoverDivCS} >
                        <Button size='small' className='hover-btn' shape="circle" icon={<DeleteOutlined />} onClick={() => { onRemove(fileInfo) }} />
                    </div>
                )}
                <img alt='error' className='thumbnail-img' src={fileInfo.fileDataUrl} />
            </div>
        </Popover>
    )
}

export default ThumbnailItem